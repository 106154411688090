import { View, StyleSheet } from 'react-native';
import React from 'react';
import TextCardInfo from '../TextComponents/Home/TextCardInfo';
import { InfoActivityProps } from '../../utils/types';
import TextCardInfoTitle from '../TextComponents/Home/TextCardInfoTitle';
import TextCardInfoMeeting from '../TextComponents/Home/TextCardInfoMeeting';

const MeetingActivityItemInfo = ({ activityViewDetail }: InfoActivityProps) => {
  console.log('activityViewDetail', activityViewDetail);
  const processItem = (item: { Name: string; Content: string }) => {
    if (!item.Content) {
      return null;
    }
    if (item.Name === 'Description') {
      return (
        <View key={item.Name}>
          <TextCardInfoTitle>{item.Name}</TextCardInfoTitle>
          <TextCardInfo>{item.Content}</TextCardInfo>
        </View>
      );
    }
    const parts = item.Content.split(' - ');
    let location: string | null = null;
    let content = null;
    let infoArray: (string | null)[] = [];

    try {
      if (parts.length === 2) {
        location = parts[0];
        const jsonStartIndex = parts[1].indexOf('{');
        if (jsonStartIndex !== -1) {
          location += parts[1].substring(0, jsonStartIndex);
          content = JSON.parse(parts[1].substring(jsonStartIndex));
        } else if (parts[1].trim().startsWith('{')) {
          // Only try to parse if it looks like JSON
          content = JSON.parse(parts[1]);
        } else {
          // It's not JSON, just use it as plain text
          infoArray = [parts[1]];
        }
      } else {
        const jsonStartIndex = parts[0].indexOf('{');
        if (jsonStartIndex !== -1) {
          location = parts[0].substring(0, jsonStartIndex);
          content = JSON.parse(parts[0].substring(jsonStartIndex));
        } else if (parts[0].trim().startsWith('{')) {
          // Only try to parse if it looks like JSON
          content = JSON.parse(parts[0]);
        } else {
          // It's not JSON, just use it as plain text
          infoArray = [parts[0]];
        }
      }
    } catch (error) {
      console.log('Error parsing JSON:', error);
      // If JSON parsing fails, treat the content as plain text
      infoArray = [item.Content];
    }

    if (content) {
      infoArray = Object.keys(content)
        //could be in capital letters or not
        .map((key) => {
          const lowerKey = key.toLowerCase();
          if (lowerKey.includes('table')) {
            return `Table: ${content ? content[key] : ''}`;
          }
          if (lowerKey.includes('main')) {
            return `Main: ${content ? content[key] : ''}`;
          }
          if (lowerKey.includes('starter')) {
            return `Starter: ${content ? content[key] : ''}`;
          }
          return null;
        })
        .filter(Boolean);
    }

    return (
      <View key={item.Name}>
        <TextCardInfoTitle>{item.Name}</TextCardInfoTitle>
        {location && <TextCardInfoMeeting>{`${location}`}</TextCardInfoMeeting>}
        {infoArray.map(
          (info, index) =>
            info !== null && <TextCardInfo key={index}>{info}</TextCardInfo>
        )}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {Array.isArray(activityViewDetail)
        ? (() => {
            // Find both Location and Description items
            const locationItem = activityViewDetail.find(
              (item) => item.Name === 'Location'
            );
            const descriptionItem = activityViewDetail.find(
              (item) => item.Name === 'Description'
            );

            return (
              <>
                {descriptionItem && processItem(descriptionItem)}
                {locationItem && processItem(locationItem)}
              </>
            );
          })()
        : activityViewDetail.Name === 'Location' ||
          activityViewDetail.Name === 'Description'
        ? processItem(activityViewDetail)
        : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 25,
  },
});

export default MeetingActivityItemInfo;
