import React from 'react';
import { Text, StyleSheet } from 'react-native';
import {
  colourConst,
  fontSize,
  fontFamilyConst,
} from '../../../../globalStyles';
import { TextProps } from '../../../../utils/types';

const TextEveningActivity = (props: TextProps) => {
  const processChild = (child: string) => {
    try {
      const parts = child.split(' - ');
      let location: string | null = null;
      let tableInfo: string | null = null;

      if (parts.length >= 1) {
        // Check if JSON is present in the first part
        const jsonStartIndex = parts[0].indexOf('{');
        if (jsonStartIndex !== -1) {
          // Extract location (text before JSON)
          location = parts[0].substring(0, jsonStartIndex).trim();

          // Try to parse the JSON part
          try {
            const jsonStr = parts[0].substring(jsonStartIndex);
            const jsonData = JSON.parse(jsonStr);

            // Look for property with "Table" in its name
            const tableProperty = Object.keys(jsonData).find((key) =>
              key.toLowerCase().includes('table')
            );

            if (tableProperty && jsonData[tableProperty]) {
              tableInfo = `Table: ${jsonData[tableProperty]}`;
            }
          } catch (jsonError) {
            console.log('Error parsing JSON:', jsonError);
          }
        } else {
          location = parts[0];

          // Check if there's JSON in the second part (if exists)
          if (parts.length >= 2 && parts[1].includes('{')) {
            try {
              const jsonStartIdx = parts[1].indexOf('{');
              const jsonStr = parts[1].substring(jsonStartIdx);
              const jsonData = JSON.parse(jsonStr);

              // Look for property with "Table" in its name
              const tableProperty = Object.keys(jsonData).find((key) =>
                key.toLowerCase().includes('table')
              );

              if (tableProperty && jsonData[tableProperty]) {
                tableInfo = `Table: ${jsonData[tableProperty]}`;
              }
            } catch (jsonError) {
              console.log('Error parsing JSON in second part:', jsonError);
            }
          }
        }
      }

      return location ? (
        <Text key={location} style={styles.text}>
          {tableInfo ? `${location} - ${tableInfo}` : location}
        </Text>
      ) : null;
    } catch (error) {
      console.log('Error processing text:', error);
      // Fallback to displaying original text if processing fails
      return child ? (
        <Text key={child} style={styles.text}>
          {child}
        </Text>
      ) : null;
    }
  };

  if (!props.children) {
    return null;
  }

  return (
    <>
      {Array.isArray(props.children)
        ? props.children.map((child) => {
            try {
              return processChild(String(child));
            } catch (error) {
              console.log('Error processing array child:', error);
              return null;
            }
          })
        : processChild(String(props.children))}
    </>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.smallRegular,
    color: colourConst.blue,
    textAlign: 'center',
    lineHeight: 14,
  },
});

export default TextEveningActivity;
